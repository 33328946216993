#video {
  border: 2px solid black;
  width: 320px;
  height: 240px;
}
#photo {
  border: 2px solid black;
  width: 100%;
  height: 240px;
}
#canvas {
  width: 100%;
  display: none;
}
#video {
  width: 100%;
}
.camera {
  width: 100%;
  display: inline-block;
}
.output {
  width: 100%;
  padding-top: 20px;
  display: grid;
  justify-items: center;
}
#startbutton {
  display: block;
  position: relative;
  margin-top: auto;
  margin: 10px;
  border-radius: 50%;
  bottom: -5px;
  padding: 15px;
  background-color: #8481da;
  border: 1px solid rgba(0, 0, 0, 0.7);
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
#downloadbutton {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  bottom: -10px;
  padding: 18px;
  background-color: #4caf50;
  border: 1px solid rgba(0, 0, 0, 0.7);
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.contentarea {
  font-size: 16px;
  font-family: Arial;
  text-align: center;
}
