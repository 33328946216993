.main_Chart {
  position: relative;
  width: 103%;
  height: 320px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

@media (min-width: 320px) and (max-width: 374px) {
  .main_Chart {
    height: 44vh;
    width: 55vh;
    margin-left: 1px;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .main_Chart {
    height: 52vh;
    width: 65vh;
    margin-left: -2px;
  }
}

@media (min-width: 425px) and (max-width: 497px) {
  .main_Chart {
    height: 60vh;
    width: 75vh;
  }
}
@media (min-width: 498px) and (max-width: 767px) {
  .main_Chart {
    height: 60vh;
    width: 100%;
    margin-left: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .main_Chart {
    height: 110vh;
    width: 100%;
    margin-left: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .main_Chart {
    height: 80vh;
    width: 103%;
  }
}

@media (min-width: 1440px) and (max-width: 2000px) {
  .main_Chart {
    height: 95vh;
    width: 100%;
    margin-left: 10px;
  }
}

@media (min-width: 2001px) and (max-width: 2600px) {
  .main_Chart {
    height: 92vh;
    width: 100%;
    margin-left: 10px;
  }
}
