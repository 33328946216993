.custom-carousel {
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.custom-carousel > div {
  width: inherit;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}
