@media (min-width: 320px) and (max-width: 374px){
    #Button_size {
        width: 240px;
      }
}


@media (min-width: 375px) and (max-width: 424px){
    #Button_size {
        width: 300px;
      }
}


@media (min-width: 425px) and (max-width: 498px) {
  #Button_size {
    width: 350px;
  }
}
