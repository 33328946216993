.react-multi-carousel-list {
  padding-bottom: 40px;
}
.testimonialButtons {
  position: absolute;
  display: flex;
  z-index: 10;
  right: 0;
  top: 0;
}

.testimonialButtons button {
  outline: 0;
  transition: all 0.5s;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  text-align: center;
}

.testimonialButtons button._right {
  margin-left: auto;
}

.testimonialButtons button._left {
  margin-right: auto;
}

.testSliderClass {
  padding-top: 35px;
}

.dividerBorder::before {
  border-top: 2px solid rgba(0, 0, 0, 0.3);
}
.dividerBorder::after {
  border-top: 2px solid rgba(0, 0, 0, 0.3);
}
