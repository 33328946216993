.waveDivider {
  position: relative;
}
.waveDivider::after {
  content: "";
  position: absolute;

  left: -0%;
  top: -15px;
  z-index: 10;
  width: 100%;
  height: 15px;
  background-size: auto;
  background-repeat: repeat no-repeat;
  background-position: 19vw bottom;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 134' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 1C19.5523 3 32.2291 5 51.5 9C104.5 19 200 43 300 65C400 88 500 111 600 102C700 93 800 53 900 30C987.526 5 1085.36 -1 1150 0C1169.54 -1 1180.49 0 1200 1V134H1150C1100 134 1000 134 900 134C800 134 700 134 600 134C500 134 400 134 300 134C200 134 100 134 50 134H0V1.98128Z' fill='%23333333'/></svg>");
}
.waveDivider::before {
  content: "";
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: -15px;
  left: 0;
  background-size: auto;
  background-repeat: repeat no-repeat;
  background-position: 19vw bottom;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 134' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 1C19.5523 3 32.2291 5 51.5 9C104.5 19 200 43 300 65C400 88 500 111 600 102C700 93 800 53 900 30C987.526 5 1085.36 -1 1150 0C1169.54 -1 1180.49 0 1200 1V134H1150C1100 134 1000 134 900 134C800 134 700 134 600 134C500 134 400 134 300 134C200 134 100 134 50 134H0V1.98128Z' fill='%23333333'/></svg>");
  transform: scaleY(-1);
}

.video-player-wrapper {
  background-color: #e3d026;
  border: 4px solid #e3d026;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
