.react-multi-carousel-list {
  padding-bottom: 40px;
}
.next-prev-buttons {
  position: absolute;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 10px;
  z-index: 10;
}

.next-prev-buttons button {
  outline: 0;
  transition: all 0.5s;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

.next-prev-buttons button._right {
  margin-left: auto;
}

.next-prev-buttons button._left {
  margin-right: auto;
}
